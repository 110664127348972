import download from 'downloadjs';
import Vue from 'vue';

/**
 * 儲存到使用者端本地資料 (cookie and LocalStorage)
 * @param {String} base64 data:image/png;base64,${base64}
 * @param {String} name ${name}.png
 * @returns {String} success
 */
const savePhoto = async function (base64, name) {
  if (Vue.prototype.$device.isMobile || Vue.prototype.$device.isTablet) {
    await Vue.prototype.$JShare.savePhoto(base64, name);
  } else {
    await download(base64, name);
  }

  return 'success';
};

export default savePhoto;
