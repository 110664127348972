<template>
  <div
    class="nft w-100 h-100 d-flex flex-column align-items-center overflow-hidden"
  >
    <base-header
      :title="headerTitle"
      :is-title-bold="true"
      :left-arrow="true"
      :back-on-click-left="true"
      :breadcrumb="breadcrumb"
      :mode="$device.isMobile ? 'secondary' : 'primary'"
    ></base-header>

    <loading-wrapper v-if="isPageLoading"></loading-wrapper>

    <EmptyPlaceholder
      v-if="!isPageLoading && !nft?.uuid"
      icon="Go-nfts"
      :text="$t('查無此 NFT')"
    />
    <div v-else class="wrapper scroll-bar">
      <div
        class="nft-container d-flex flex-column flex-md-row justify-content-start align-items-start"
      >
        <div class="img-wrapper position-relative mb-4">
          <div class="nft-pic" :style="nftImg"></div>
          <div class="btn-wrapper text-center mt-0 mt-md-4">
            <b-button
              size="oval-sm"
              variant="primary"
              class="preview-btn border-0"
              :class="$device.isMobile ? ' position-absolute ' : ''"
              @click="isPreviewShow = true"
            >
              <i class="icon-Img-zoomin d-block text-white"></i
            ></b-button>
            <b-button
              v-if="!$device.isMobile"
              size="oval-sm"
              variant="primary"
              class="download-btn border-0 ml-3"
              @click="onClickDownload"
            >
              <i class="icon-Download d-block text-white"></i>
            </b-button>
          </div>
        </div>
        <div class="info-wrppaer w-100 px-4 px-md-0 pb-4 pb-md-0">
          <h3 class="nft-name text-20 mb-4">{{ nft.name }}</h3>
          <p class="mb-4">{{ $t('敘述') }}：</p>

          <p
            v-for="(description, index) in descriptionArr"
            :key="index"
            class="description mb-4"
          >
            {{ description }}
          </p>
          <b-button
            size="lg"
            variant="primary"
            class="w-100 mb-4 font-weight-bold"
            @click="onClickKifuPreview"
          >
            {{ $t('查看棋譜') }}
          </b-button>
          <p class="mb-4">{{ $t('屬性') }}：</p>
          <div class="attributes mb-4 text-center">
            <div
              v-for="(attribute, index) in nft.attributes"
              :key="index"
              class="attribute text-truncate"
            >
              <div class="type">{{ $t(attribute.trait_type) }}</div>
              <div class="value text-18 font-weight-bold text-truncate">
                {{ $t(attribute.value) }}
              </div>
            </div>
          </div>
          <p class="mb-4">{{ $t('細節') }}：</p>
          <ul class="p-0">
            <li
              v-for="(detail, index) in details"
              :key="detail.title"
              class="d-flex justify-content-between"
              :class="{'mb-2': index !== details.length - 1}"
            >
              <span class="title mr-3 w-50"> {{ detail.title }}</span>
              <a
                v-if="detail.href"
                :href="detail.href"
                target="_blank"
                class="text-truncate w-50 text-right"
                @click="onClick2Contract($event, detail.href)"
                >{{ detail.text }}</a
              >
              <span v-else class="text-truncate w-50 text-right">
                {{ detail.text }}</span
              >
            </li>
          </ul>
          <b-button
            size="lg"
            variant="primary"
            class="w-100 font-weight-bold"
            @click="onClick2Detail"
          >
            {{ $t('詳細賦能及說明') }}
          </b-button>
        </div>
      </div>
    </div>
    <NftPreview
      :show="isPreviewShow"
      :img="imgBase64"
      @close="isPreviewShow = false"
      @download="onClickDownload"
    />
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import userService from '@/services/user';
import EmptyPlaceholder from '@/components/Base/EmptyPlaceholder.vue';
import LoadingWrapper from '@/components/Base/LoadingWrapper.vue';
import {Browser} from '@capacitor/browser';
import NftPreview from '@/components/Personal/NftPreview.vue';
import axios from 'axios';
import savePhoto from '@/lib/base/savePhoto.js';
import {manifest} from '@/constant/env.js';

export default {
  components: {
    BaseHeader,
    EmptyPlaceholder,
    LoadingWrapper,
    NftPreview,
  },
  data() {
    return {
      nft: {},
      isPageLoading: false,
      offsetWidth: 0,
      isPreviewShow: false,
      imgBase64: '',
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters['env/isLoading'];
    },
    uuid() {
      return this.$route.params.uuid;
    },
    headerTitle() {
      return this.nft?.name || 'NFT';
    },
    descriptionArr() {
      return (this.nft?.description || '').split('\n');
    },
    nftImg() {
      return {
        height: this.$device.isMobile ? `${this.offsetWidth}px` : null,
        backgroundImage: `url(${this.imgBase64})`,
      };
    },
    details() {
      return [
        {
          title: 'Contract Address',
          text: '0xf7D34758379FfFa4c4C0b8a96fca2Ed50b89D4AE',
          href: 'https://viewblock.io/thundercore/address/0xf7d34758379fffa4c4c0b8a96fca2ed50b89d4ae?tab=code',
        },
        {
          title: 'Token ID',
          text: this.nft?.tokenId || '-',
        },
        {
          title: 'Token Standard',
          text: 'TT-721',
        },
        {
          title: 'Blockchain',
          text: 'TT',
        },
      ];
    },
    breadcrumb() {
      return [
        {
          name: this.$t('個人'),
          enable: true,
          router: 'personal',
        },
        {
          name: 'NFTs',
          enable: true,
          router: 'nfts',
        },
        {
          name: this.headerTitle,
          enable: true,
          router: 'nft',
        },
      ];
    },
  },
  created() {
    this.getNft();
  },
  mounted() {
    if (this.$device.isMobile) {
      this.offsetWidth = document.querySelector('.img-wrapper').offsetWidth;
    }
  },
  methods: {
    async getNft() {
      try {
        this.isPageLoading = true;

        const result = await userService.getNft(this.uuid);
        this.nft = result;
        await this.getImg();
      } finally {
        this.isPageLoading = false;
      }
    },
    async getImg() {
      try {
        const {data} = await axios({
          method: 'GET',
          url: `${this.nft.image}?get=nft`,
          responseType: 'arraybuffer',
        });
        const base64 = Buffer.from(data, 'binary').toString('base64');
        this.imgBase64 = 'data:image/png;base64,' + base64;
      } catch (error) {
        throw error;
      }
    },
    onClickNft(nft) {
      if (nft.isBlind) {
        this.isTipsShow = true;
      } else {
        this.$router.push({name: 'nft', params: nft.uuid});
      }
    },
    onClickKifuPreview() {
      return this.$router.push(`/personal/kifu/${this.nft.uuid}/nft`);
    },
    async onClick2Contract(e, url) {
      if (this.$device.isMobile) {
        e.preventDefault();
        await Browser.open({
          url,
          presentationStyle: 'popover',
        });
      }
    },
    async onClickDownload() {
      if (this.isLoading) {
        return;
      }
      try {
        this.$store.commit('env/setIsLoading', true);

        savePhoto(this.imgBase64, `${this.nft.name}#${this.nft.tokenId}.png`);
        this.$Message.success(this.$t('下載成功'));
      } catch {
        this.$Message.error(this.$t('下載失敗'));
      } finally {
        this.$store.commit('env/setIsLoading', false);
      }
    },
    async onClick2Detail() {
      const homePageUrl = manifest.homePageUrl;

      await Browser.open({
        url: homePageUrl + '/events/gothroughtheages/',
        presentationStyle: 'popover',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.nft {
  .wrapper {
    width: 100%;
    height: calc(100vh - 56px);
    overflow: overlay;
  }
  .nft-container {
    width: 100%;
    margin: 0 auto;
  }
  .img-wrapper {
    width: 100%;
  }
  .nft-pic {
    width: 100%;
    background-size: cover;
  }
  .preview-btn,
  .download-btn {
    right: 16px;
    bottom: 16px;
    padding: 10px;
  }
  .icon-Img-zoomin {
    font-size: 30px;
  }
  .attributes {
    display: grid;
    grid-auto-rows: min-content;
    grid-auto-columns: min-content;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  }
  .attribute {
    border: 1px solid $font-grayscale-1;
    padding: 8px 12px;
    border-radius: $rounded-md;
  }
  .type {
    color: $font-grayscale-2;
    margin-bottom: 2px;
  }
  ul {
    list-style: none;
    .title {
      color: $font-grayscale-2;
    }
  }
}

@media screen and (min-width: 768px) {
  .nft {
    .wrapper {
      height: calc(100vh - 80px);
    }
    .nft-container {
      max-width: 720px;
      padding: 40px;
      margin: 40px auto;
      background: $white;
      border-radius: $rounded-md;
      box-shadow: 0px 2px 7px rgba(199, 199, 199, 0.3);
    }
    .img-wrapper {
      margin-right: 40px;
      width: auto;
    }
    .nft-pic {
      width: 321px;
      height: 321px;
      border-radius: $rounded-md;
    }
    ul {
      width: 279px;
    }
  }
}

@media screen and (min-width: 1440px) {
  .nft {
    .nft-container {
      max-width: 1140px;
    }
    ul {
      width: 100%;
    }
  }
}
</style>
