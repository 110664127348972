<template>
  <modal-wrapper
    v-if="show"
    :is-modal-wrapper-show="show"
    :is-show-transition="false"
    @close="close"
  >
    <div class="nft-preview w-100 h-100 overflow-hidden position-relative">
      <i
        class="icon-X position-absolute text-white text-32 cursor-pointer"
        @click.stop="close"
      >
      </i>
      <div class="nft-img position-absolute" :style="nftImg" @click.stop></div>
      <b-button
        size="oval-sm"
        variant="primary"
        class="download-btn border-0 position-absolute"
        @click.stop="onClickDownload"
      >
        <i class="icon-Download d-block text-white"></i>
      </b-button>
    </div>
  </modal-wrapper>
</template>

<script>
import ModalWrapper from '@/components/Base/ModalWrapper.vue';

export default {
  components: {
    ModalWrapper,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    img: {
      type: String,
      default: '',
    },
  },
  computed: {
    nftImg() {
      return {
        backgroundImage: `url('${this.img}')`,
      };
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    onClickDownload() {
      this.$emit('download');
    },
  },
};
</script>

<style lang="scss" scoped>
.nft-preview {
  .icon-X {
    top: 62px;
    right: 16px;
  }
  .nft-img {
    width: 343px;
    height: 343px;
    background-size: contain;
    background-repeat: no-repeat;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .icon-Download {
    font-size: 30px;
  }
  .download-btn {
    padding: 10px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, calc(343px / 2 + 24px));
  }
}

@media screen and (min-width: 768px) {
  .nft-preview {
    .icon-X {
      top: 100px;
      right: 120px;
    }
    .nft-img {
      width: 560px;
      height: 560px;
    }
    .download-btn {
      transform: translate(-50%, calc(560px / 2 + 24px));
    }
  }
}
</style>

<style>
.modal-wrapper {
  background: rgba(0, 0, 0, 0.8) !important;
}
</style>
